import React, {useEffect, useRef, useState} from 'react';
import AlphaPatternCanvas from "./AlphaPatternCanvas";
import {ActionIcon, Alert as MantineAlert, AppShell, Dialog, Grid, Tooltip,} from '@mantine/core';
import Toolbar from "./Toolbar";
import {createCell} from "../../Utils/cell";
import {useLocation} from "react-router-dom";
import ToolSelect from "./ToolSelect";
import Coordinates from "./Coordinates";
import Scale from "./Scale";
import {IconDeviceFloppy, IconInfoCircle} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {v4 as uuidv4} from "uuid";


const AlphaPatternEdit = () => {
    const location = useLocation();
    const canvasRef = useRef(null);


    const [gridWidth, setGridWidth] = useState(location.state ? location.state.imageGridData[0].length : 49);
    const [gridHeight, setGridHeight] = useState(location.state ? location.state.imageGridData.length : 49);
    const [gridData, setGridData] = useState(location.state ?
        (
            location.state.imageGridData
        ) :
        (
            Array.from({length: gridHeight}, (_, y) =>
                Array.from({length: gridWidth}, (_, x) => (createCell(x, y)))
            ))
    )
    const [name, setName] = useState(location.state ? location.state.name : "")
    const [uuid, setUuid] = useState(location.state ? location.state.uuid: null)
    const [selectedColor, setSelectedColor] = useState('#000000');
    const [paintMode, setPaintMode] = useState("brush")
    const [lines, setLines] = useState([])
    const [currentLine, setCurrentLine] = useState({
        from: "",
        to: "",
    })
    const [viewport, setViewport] = useState({x: 0, y: 0, scale: 1});
    const [cellSize, setCellSize] = useState(10)
    const [zoomDisplay, setZoomDisplay] = useState(0);
    const [currentFill, setCurrentFill] = useState({
        from: "",
        to: "",
    })
    const [currentCell, setCurrentCell] = useState({x: 0, y: 0})
    const [isMouseHeld, setIsMouseHeld] = useState(false);
    const [countCell, setCountCell] = useState(null)
    const [accordion, setAccordion] = useState("paint")
    const [currentCells, setCurrentCells] = useState(gridData[0])
    const [severity, setSeverity] = React.useState('warning')
    const [opened, {toggle, close}] = useDisclosure(false);

    const handleCellClick = (cell, x, y) => {
        if (paintMode === "brush") {
            setCurrentLine({
                from: "",
                to: "",
            })
            const updatedGridData = [...gridData];
            updatedGridData[y] = [...gridData[y]];
            updatedGridData[y][x] = {...cell, color: selectedColor};
            setGridData(updatedGridData)
        } else if (paintMode === "line") {
            if (currentLine.from) {
                const newLine = {
                    from: currentLine.from,
                    to: {x , y},
                    color: selectedColor,
                }
                const updatedLines = [...lines, newLine];
                setLines(updatedLines)
                setCurrentLine({from: "", to: ""})
            } else {
                setCurrentLine((prevLine) => ({
                    ...prevLine,
                    from: {x, y},
                    to: "",
                }));
            }
        } else if (paintMode === "fill") {
            if (currentFill.from) {
                fillArea(currentFill.from, {x, y})
                setCurrentFill({
                    from: "",
                    to: ""
                });
            } else {
                setCurrentFill({
                    from: {x, y},
                    to: "",
                });
            }
        } else if (paintMode === "erase") {
            const updatedLines = lines.filter(line => {
                // Check if the point is within the bounding box of the line segment
                const withinX = x >= Math.min(line.from.x, line.to.x) && x <= Math.max(line.from.x, line.to.x);
                const withinY = y >= Math.min(line.from.y, line.to.y) && y <= Math.max(line.from.y, line.to.y);
                if (!withinX || !withinY) {
                    return true;
                }
                // Calculate the distance from the point to the line using the distance formula
                const distance = Math.abs((line.to.y - line.from.y) * x - (line.to.x - line.from.x) * y + line.to.x * line.from.y - line.to.y * line.from.x) /
                    Math.sqrt(Math.pow(line.to.y - line.from.y, 2) + Math.pow(line.to.x - line.from.x, 2));
                // Set a threshold for how close the click should be to consider it a hit
                const threshold = 1;

                return distance > threshold;
            });
            setLines(updatedLines)
        }
    };

    const fillArea = (from, to) => {
        const {x: startX, y: startY} = from;
        const {x: endX, y: endY} = to;

        const sortedStartX = Math.min(startX, endX);
        const sortedStartY = Math.min(startY, endY);
        const sortedEndX = Math.max(startX, endX);
        const sortedEndY = Math.max(startY, endY);

        const updatedGridData = gridData.map(row =>
            row.map(cell => {
                const {x, y} = cell;
                if (sortedStartX <= x && x <= sortedEndX && sortedStartY <= y && y <= sortedEndY) {
                    return {...cell, color: selectedColor};
                }
                return cell;
            })
        );
        setGridData(updatedGridData);
    };

    const handleSave = async (e) => {
        setSeverity('loading');
        if (!opened) toggle();

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = gridData[0].length;
        canvas.height = gridData.length;
        for (var y = 0; y < gridData.length; y++) {
            for (var x = 0; x < gridData[y].length; x++) {
                context.fillStyle = gridData[y][x].color;
                context.fillRect(x, y, 1, 1);
            }
        }
        const dataURL = canvas.toDataURL();

        fetch(dataURL)
            .then(res => res.blob())
            .then((blob) => {
                const newUuid = uuidv4()
                const file = new File([blob], `${uuid ? uuid : newUuid}.jpg`, {type: 'image/jpeg'});
                const formData = new FormData();
                formData.append('uuid', uuid ? uuid : newUuid)
                formData.append('file', file);
                formData.append('name', name);
                let method = 'POST'
                let url = `${process.env.REACT_APP_API_HOST}/alpha_pattern/`
                if (uuid) {
                    method = 'PUT'
                    url += `${uuid}/`
                }
                return makeAuthenticatedRequest(
                    url,
                    method,
                    formData,
                    null
                );
            })
            .then((response) => {
                if (response.ok) {
                    setSeverity('success');
                    if (!opened) toggle();
                    canvas.remove()
                } else {
                    setSeverity('warning');
                    if (!opened) toggle();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setSeverity('warning');
                if (!opened) toggle();
            });
    }


    useEffect(() => {
        setCurrentLine({
            from: "",
            to: "",
        })
        setCurrentFill({
            from: "",
            to: "",
        })
    }, [accordion]);

    return (
        <React.Fragment>
            <AppShell.Main>
                <Grid
                    align="center"
                    justify="center"
                >
                    <Grid.Col h="7vh" mah="7vh" mih="7vh" span={1}></Grid.Col>
                    <Grid.Col h="7vh" mah="7vh" mih="7vh" span={9}>
                        <div style={{ paddingTop: "10px" }}>
                            <Scale
                                zoomDisplay={zoomDisplay}
                                setZoomDisplay={setZoomDisplay}
                                viewport={viewport}
                                setViewport={setViewport}
                                gridWidth={gridWidth}
                                gridHeight={gridHeight}
                            />
                        </div>
                    </Grid.Col>
                    <Grid.Col h="7vh" mah="7vh" mih="7vh" span={2}>
                        <div style={{ paddingTop: "10px" }}>
                            <Coordinates
                                currentCell={currentCell}
                            />
                        </div>
                    </Grid.Col>

                    <Grid.Col h="83vh" mah="83vh" mih="83vh" span={1}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>

                            <ToolSelect
                                accordion={accordion}
                                setAccordion={setAccordion}
                            />
                            <Tooltip label="Save">
                                <ActionIcon
                                    variant="gradient"
                                    size="xl"
                                    aria-label="Save Icon"
                                    color="violet"
                                    mt={10}
                                    onClick={handleSave}
                                >
                                    <IconDeviceFloppy/>
                                </ActionIcon>
                            </Tooltip>
                        </div>
                    </Grid.Col>

                    <Grid.Col h="83vh" mah="83vh" mih="83vh" span={11}>
                        <AlphaPatternCanvas
                            gridData={gridData}
                            cellSize={cellSize}
                            setCellSize={setCellSize}
                            onCellClick={handleCellClick}
                            lines={lines}
                            currentLine={currentLine}
                            setCurrentLine={setCurrentLine}
                            viewport={viewport}
                            setViewport={setViewport}
                            gridWidth={gridWidth}
                            gridHeight={gridHeight}
                            currentFill={currentFill}
                            setCurrentFill={setCurrentFill}
                            setCurrentCell={setCurrentCell}
                            isMouseHeld={isMouseHeld}
                            setIsMouseHeld={setIsMouseHeld}
                            paintMode={paintMode}
                            countCell={countCell}
                            currentCells={currentCells}
                            accordion={accordion}
                            canvasRef={canvasRef}
                        />
                    </Grid.Col>
                </Grid>
            </AppShell.Main>
            <AppShell.Aside style={{overflow: "scroll"}}>
                            <Toolbar
                                gridData={gridData}
                                setGridData={setGridData}
                                gridWidth={gridWidth}
                                gridHeight={gridHeight}
                                setGridWidth={setGridWidth}
                                setGridHeight={setGridHeight}
                                selectedColor={selectedColor}
                                setSelectedColor={setSelectedColor}
                                paintMode={paintMode}
                                setPaintMode={setPaintMode}
                                lines={lines}
                                setLines={setLines}
                                currentLine={currentLine}
                                setCurrentLine={setCurrentLine}
                                currentFill={currentFill}
                                setCurrentFill={setCurrentFill}
                                countCell={countCell}
                                setCountCell={setCountCell}
                                accordion={accordion}
                                setAccordion={setAccordion}
                                currentCells={currentCells}
                                setCurrentCells={setCurrentCells}
                            />
            </AppShell.Aside>
            <Dialog component={MantineAlert} variant="dark"
                    color={
                        severity === "success" ? "green" :
                            severity === "warning" ? "red" :
                                "violet"
                    }
                    title={
                        severity === "success" ? "Pattern saved!" :
                            severity === "warning" ? "An error occurred. Please try again" :
                                "saving..."
                    }
                    icon={<IconInfoCircle/>}
                    opened={opened} withCloseButton onClose={close} size="lg" radius="md">
            </Dialog>
        </React.Fragment>
    );
}

export default AlphaPatternEdit;
