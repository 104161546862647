import React, {useState} from 'react';
import {useLocation} from "react-router-dom";
import {v4 as uuidv4} from "uuid";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {
    ActionIcon,
    Alert as MantineAlert,
    AppShell,
    Badge,
    Center,
    Dialog,
    Group,
    Paper,
    Text,
    Tooltip,
    Table, ScrollArea, getThemeColor, useMantineTheme, Grid, Stack,
} from "@mantine/core";

import {IconDeviceFloppy, IconInfoCircle, IconMinus, IconPlus, IconX} from "@tabler/icons-react";
import {useDisclosure} from "@mantine/hooks";

const RowCounterEdit = () => {
    const location = useLocation();
    const theme = useMantineTheme()

    const [name, setName] = useState(location.state?.name ? location.state.name : "");
    const [stitch, setStitch] = useState(location.state?.stitch ? location.state.stitch : 0)
    const [rows, setRows] = useState(location.state?.rows ? location.state.rows : [])
    const [stitchIncrement, setStitchIncrement] = useState(1)
    const [uuid, setUuid] = useState(location.state?.uuid ? location.state.uuid : null)
    const [severity, setSeverity] = React.useState('warning')
    const [opened, {toggle, close}] = useDisclosure(false);


    const handleSave = async (e) => {
        setSeverity('loading');
        if (!opened) toggle();
        const newUuid = uuidv4()
        const body = {
            uuid: uuid ? uuid : newUuid,
            name: name,
            stitch: stitch,
            rows: rows,
        }
        let method = 'POST'
        let url = `${process.env.REACT_APP_API_HOST}/row_counter/`
        if (uuid) {
            method = 'PUT'
            url += `${uuid}/`
        }
        return makeAuthenticatedRequest(
            url,
            method,
            JSON.stringify(body),
        )
            .then((response) => {
                if (response.ok) {
                    setSeverity('success');
                    if (!opened) toggle();
                } else {
                    setSeverity('warning');
                    if (!opened) toggle();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setSeverity('warning');
                if (!opened) toggle();
            });
    }

    const handleStitchChange = (diff) => {
        const incrementDiff = (stitchIncrement * diff)
        setStitch(stitch + incrementDiff)
    }

    const addRow = () => {
        const updatedRows = [...rows, (stitch + 1)]
        setRows(updatedRows)
        setStitch(0)
    }

    const deleteRow = (index) => {
        const updatedRows = rows.filter((row, rowIndex) => rowIndex !== index);
        setRows(updatedRows)
    }

    return (
        <React.Fragment>
            <AppShell.Main>
                <Grid h={"90vh"}>
                    <Grid.Col h={"75vh"} span={2}>
                        <ActionIcon w={"100%"} h={"50%"} size="lg" disabled={stitch === 10000} onClick={() => {
                            handleStitchChange(1)
                        }}>
                            <IconPlus size={20}/>
                        </ActionIcon>
                        <ActionIcon w={"100%"} h={"50%"} size="lg" disabled={(stitch - stitchIncrement) < 0} onClick={() => {
                            handleStitchChange(-1)
                        }}>
                            <IconMinus size={20}/>
                        </ActionIcon>
                    </Grid.Col>
                    <Grid.Col h={"75vh"} span={9}>
                        <Center style={{ height: '100%' }}>
                            <Stack>
                                <Center>
                                <Text style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: '15vw', // Adjust this value as needed for scaling
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                }}>
                                    {stitch + 1}
                                </Text>
                                    </Center>
                                <Center>
                                <Text>Stitches</Text>
                                    </Center>
                            </Stack>
                        </Center>
                    </Grid.Col>
                                        <Grid.Col h={"75vh"} span={1}>
                                                            <Tooltip label="Save">
                    <ActionIcon
                        variant="gradient"
                        size="xl"
                        aria-label="Save Icon"
                        color="violet"
                        mt={10}
                        onClick={handleSave}
                    >
                        <IconDeviceFloppy/>
                    </ActionIcon>
                </Tooltip>
                                        </Grid.Col>
                    <Grid.Col h={"15vh"} span={2}>
                        <ActionIcon w={"100%"} h={"50%"} size="lg" disabled={stitchIncrement > 100} onClick={() => {
                            setStitchIncrement(stitchIncrement + 1)
                        }}>
                            <IconPlus size={20}/>
                        </ActionIcon>
                        <ActionIcon w={"100%"} h={"50%"} size="lg" disabled={stitchIncrement === 1} onClick={() => {
                            setStitchIncrement(stitchIncrement - 1)
                        }}>
                            <IconMinus size={20}/>
                        </ActionIcon>
                    </Grid.Col>
                    <Grid.Col h={"15vh"} span={9}>
                        <Center style={{ height: '100%' }}>
                            <Stack>
                                <Center>
                            <Text style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '3vw', // Adjust this value as needed for scaling
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}>
                                {stitchIncrement}
                            </Text>
                                    </Center>
                                <Center>
                                    <Text>Increment</Text>
                                </Center>
                                </Stack>
                        </Center>
                    </Grid.Col>
                                        <Grid.Col h={"15vh"} span={1}>
                                        </Grid.Col>
                </Grid>
            </AppShell.Main>
            <AppShell.Aside>
                <ScrollArea h={'85vh'}>
                <Table
                    withTableBorder
                    highlightOnHover
                    withColumnBorders
                    withRowBorders={false}
                >
                    <Table.Thead
                        style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: getThemeColor('violet', theme),
                            zIndex: 1
                    }}
                    >
                        <Table.Tr>
                          <Table.Th><Center>Row</Center></Table.Th>
                          <Table.Th><Center>Stitches</Center></Table.Th>
                          <Table.Th><Center>Delete</Center></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                {rows.map((row, rowIndex) => {
                    return <Table.Tr key={rowIndex}>
                                <Table.Td><Center>{rowIndex + 1}</Center></Table.Td>
                                <Table.Td><Center>{row}</Center></Table.Td>
                                <Table.Td>
                                    <Center>
                                        <Tooltip label="Delete Row">
                                        <ActionIcon
                                            onClick={() => {
                                                deleteRow(rowIndex)
                                            }}
                                        >
                                            <IconX size={20}/>
                                        </ActionIcon>
                                        </Tooltip>
                                    </Center>
                                </Table.Td>
                    </Table.Tr>
                })}
                    </Table.Tbody>
                </Table>
                </ScrollArea>
                <Tooltip label="Add Row">
                <ActionIcon
                    size="lg"
                    disabled={rows.length > 1000}
                    onClick={() => {
                        addRow()
                    }}
                    style={{ height: '5vh', width: '100%' }}
                >
                    <IconPlus size={20}/>
                </ActionIcon>
                </Tooltip>
            </AppShell.Aside>
            <Dialog component={MantineAlert} variant="dark"
                    color={
                        severity === "success" ? "green" :
                            severity === "warning" ? "red" :
                                "violet"
                    }
                    title={
                        severity === "success" ? "Counter saved!" :
                            severity === "warning" ? "An error occurred. Please try again" :
                                "saving..."
                    }
                    icon={<IconInfoCircle/>}
                    opened={opened} withCloseButton onClose={close} size="lg" radius="md">
            </Dialog>
        </React.Fragment>
    );
}

export default RowCounterEdit;
