import {Center, ColorInput, Divider, rem, ScrollArea, SegmentedControl, Stack, Tooltip} from "@mantine/core";
import {IconBrush, IconBucketDroplet, IconEraser, IconRoute2} from "@tabler/icons-react";
import React from "react";

const PaintCanvas = (
    {
        paintMode,
        setPaintMode,
        selectedColor,
        setSelectedColor,
    }) => {

    return (
            <Stack>
                <Divider label="Tool" labelPosition="center"/>
                <Center>
                        <SegmentedControl
                            value={paintMode}
                            onChange={setPaintMode}
                            color="violet"
                            orientation="vertical"
                            data={[
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Paint">
                                                <IconBrush style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Vertical</Box>*/}
                                        </Center>
                                    ),
                                    value: 'brush'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Line">
                                                <IconRoute2 style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'line'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Fill">
                                                <IconBucketDroplet style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'fill'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Erase">
                                                <IconEraser style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'erase'
                                },
                            ]}
                        />
                    </Center>
                <Divider label="Color" labelPosition="center"/>
                        <ColorInput
                            value={selectedColor}
                            onChange={setSelectedColor}
                            format="hex"
                            swatches={[
                                "#25262b",
                                "#868e96",
                                "#fa5252",
                                "#e64980",
                                "#be4bdb",
                                "#7950f2",
                                "#4c6ef5",
                                "#228be6",
                                "#15aabf",
                                "#12b886",
                                "#40c057",
                                "#82c91e",
                                "#fab005",
                                "#fd7e14",
                            ]}
                        />
                </Stack>
    )

}

export default PaintCanvas;