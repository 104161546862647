import {Center, ColorInput, rem, ScrollArea, SegmentedControl, Stack, Tooltip} from "@mantine/core";
import {
    IconAbacus,
    IconArrowAutofitRight, IconAspectRatio,
    IconBrush,
    IconBucketDroplet,
    IconEraser,
    IconPencil,
    IconRoute2
} from "@tabler/icons-react";
import React from "react";

const ToolSelect = (
    {
        accordion,
        setAccordion,
    }) => {

    return (
            <Stack>
                <Center>
                        <SegmentedControl
                            value={accordion}
                            onChange={setAccordion}
                            color="violet"
                            orientation="vertical"
                            data={[
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Edit">
                                                <IconPencil style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Vertical</Box>*/}
                                        </Center>
                                    ),
                                    value: 'paint'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Count">
                                                <IconAbacus style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'count'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Direction">
                                                <IconArrowAutofitRight style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'direction'
                                },
                                {
                                    label: (
                                        <Center>
                                            <Tooltip label="Size">
                                                <IconAspectRatio style={{width: rem(16), height: rem(16)}}/>
                                            </Tooltip>
                                            {/*<Box ml={10}>Diagonal</Box>*/}
                                        </Center>
                                    ),
                                    value: 'size'
                                },
                            ]}
                        />
                    </Center>
                </Stack>
    )

}

export default ToolSelect;