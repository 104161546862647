import React, {useContext, useEffect} from 'react';

import {BackgroundImage, Button, Card, Center, Stack, Text, Textarea, TextInput, Title} from "@mantine/core";
import mySvg from "../../Images/crochetDesign.svg";
import {UserContext} from "../../Context/User";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {notifications} from "@mantine/notifications";
import {isEmail, useForm} from "@mantine/form";


const Feedback = () => {
    const {user} = useContext(UserContext);

    const form = useForm({
        mode: 'uncontrolled',
        validateInputOnChange: true,
        initialValues: {name: '', email: '', message: ''},
        validate: {
            email: isEmail('Invalid email'),
            message: (value) => value.length < 1000 ? null : `Feedback message too long ${value.length}/1000`,
        },
    });

    useEffect(() => {
        if (user) {
            form.setValues({name: user.name, email: user.email, message: ''});
        }
    }, [user]);


    const handleSubmitFeedback = async (values) => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/feedback/`,
                'POST',
                JSON.stringify({"name": values.name, "email": values.email, "message": values.message})
            );
            if (response.ok) {
                notifications.show({
                    title: 'Feedback successfully sent',
                    message: 'Thank you!',
                })
            } else {
                // Handle error if the response is not ok
                console.error('Error:', response.status);
            }
        } catch (error) {
            // Handle error if the request fails
            console.error('Error:', error);
        }
    };

    return (
            <Card
                shadow="sm"
                radius="md"
                withBorder
                style={{margin: "10vh", marginLeft: "15%", marginRight: "15%"}}
            >
                <Card.Section>
                <BackgroundImage src={mySvg}
                                 style={{height: "100%", borderImage: "fill 0 linear-gradient(#0003, #000)"}}>
                    <Center>
                        <Title style={{zIndex: 2}} c={"white"} p={"md"}>Feedback</Title>
                    </Center>
                </BackgroundImage>
            </Card.Section>
            <form onSubmit={form.onSubmit((values) => handleSubmitFeedback(values))}>
                <Stack mt={"md"}>
                    <Text fw={800}>
                        Feature suggestions, bugs, or just want to chat? We're always trying to
                        improve, and we're happy to hear what you think!
                    </Text>
                    <TextInput required
                               label="Name"
                               placeholder="Your name"
                               {...form.getInputProps('name')}
                    />
                    <TextInput required
                               label="Email"
                               placeholder="Your email"
                               {...form.getInputProps('email')}
                    />
                    <Textarea required
                              {...form.getInputProps('message')}
                              placeholder="Write your feedback here!"
                              label="Feedback"
                              autosize
                              minRows={2}
                              maxRows={4}
                    />
                    <Button type="submit">Submit feedback</Button>
                </Stack>

            </form>

        </Card>
    )

}

export default Feedback;