import React, {useEffect} from 'react';
import {
    ActionIcon,
    AppShell,
    Button, Center,
    getThemeColor,
    Grid,
    Image,
    ScrollArea,
    Table, Tooltip,
    useMantineColorScheme, useMantineTheme
} from "@mantine/core";
import {makeAuthenticatedRequest} from "../../Utils/authenticated_request";
import {IconChevronLeft, IconChevronRight, IconPlus, IconX} from "@tabler/icons-react";

export const lightVioletFilter = "invert(45%) sepia(99%) saturate(3035%) hue-rotate(232deg) brightness(96%) contrast(103%)"
export const darkVioletFilter = "invert(36%) sepia(58%) saturate(1769%) hue-rotate(232deg) brightness(80%) contrast(116%)"
export const whiteFilter = "invert(100%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%)"

const PatternBuilderManager = () => {
    const [patternBuilderItems, setPatternBuilderItems] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState(null)
    const [itemIndex, setItemIndex] = React.useState(null)
    const [partsIndexes, setPartsIndexes] = React.useState(null)
    const [selectedPart, setSelectedPart] = React.useState(null)
    const [hovering, setHovering] = React.useState(null)

    const { colorScheme } = useMantineColorScheme();
    const theme = useMantineTheme()



    const fetchPatternBuilderItems = async () => {
        try {
            const response = await makeAuthenticatedRequest(
                `${process.env.REACT_APP_API_HOST}/pattern_builder/`
            );

            if (response.ok) {
                const jsonData = await response.json();
                setPatternBuilderItems(jsonData.results)
                setItemIndex(0)
            } else {
                console.error("Request failed with status:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleItemCarousel = (increment) => {
        if ((itemIndex + increment) > (patternBuilderItems.length - 1)) {
            setItemIndex(0)
        } else if ((itemIndex + increment) < 0) {
            setItemIndex(patternBuilderItems.length - 1)
        } else {
            setItemIndex(itemIndex + increment)
        }
    }

    const handlePartCarousel = (part, increment) => {
        const parts = selectedItem.parts[part]
        const updatedPartsIndexes = {...partsIndexes}
        if ((partsIndexes[part] + increment) > (parts.length - 1)) {
            updatedPartsIndexes[part] = 0
        } else if ((partsIndexes[part] + increment) < 0) {
            updatedPartsIndexes[part] = parts.length - 1
        } else {
            updatedPartsIndexes[part] = partsIndexes[part] + increment
        }
        setPartsIndexes(updatedPartsIndexes)
    }

    const handleSelectItem = (itemIndex) => {
        setSelectedItem(patternBuilderItems[itemIndex])
        const partsIndexes = Object.keys(patternBuilderItems[itemIndex].parts).reduce((acc, key) => {
            acc[key] = 0;
            return acc;
        }, {});
        setPartsIndexes(partsIndexes)
    }

    const getFilter = (partName) => {
        return (
            selectedPart === partName?
                lightVioletFilter :
                (hovering === partName ? darkVioletFilter :
                    (colorScheme === "dark" ? whiteFilter : "")
                )
        )
    }

    useEffect(() => {
        fetchPatternBuilderItems()
    }, []);

    return (
        <React.Fragment>
            <AppShell.Main>
                {!selectedItem &&
                    <React.Fragment>
                        <Grid h={"80vh"}>
                            <Grid.Col h="100%" span={1}>
                                <ActionIcon variant="gradient" size="xl" aria-label="Previous-Item" onClick={() => {handleItemCarousel(-1)}}>
                                    <IconChevronLeft/>
                                </ActionIcon>
                            </Grid.Col>
                            <Grid.Col h="100%" span={10}>
                                {patternBuilderItems[itemIndex] && patternBuilderItems[itemIndex].name}
                            </Grid.Col>
                            <Grid.Col h="100%" span={1}>
                                <ActionIcon variant="gradient" size="xl" aria-label="Next-Item" onClick={() => {handleItemCarousel(1)}}>
                                    <IconChevronRight/>
                                </ActionIcon>
                            </Grid.Col>
                        </Grid>
                        <Button onClick={() => handleSelectItem(itemIndex)}>Select Item</Button>
                    </React.Fragment>
                }
                {selectedItem && selectedItem.type === "TOP" &&
                    <Grid h="90vh" columns={24}>
                        <Grid.Col h="90vh" span={3}>
                            <ActionIcon ml="5%" mt="5vh" w="90%" h="80vh" variant="gradient" aria-label="Previous-Part" onClick={() => {handlePartCarousel("ARMS", -1)}}>
                                <IconChevronLeft/>
                            </ActionIcon>
                        </Grid.Col>

                        <Grid.Col h="90vh" span={6}>
                                <Image
                                    onClick={() => setSelectedPart("ARMS")}
                                    mt={"30%"}
                                    radius="md"
                                    src={selectedItem.parts["ARMS"][partsIndexes.ARMS].image}
                                    crossOrigin="Anonymous"
                                    style={{
                                        transform: "scaleX(-1)",
                                        height: '40%',
                                        objectFit: 'contain',
                                        filter: getFilter("ARMS"),
                                    }}
                                    onMouseEnter={() => setHovering("ARMS")}
                                      onMouseLeave={() => setHovering(null)}
                                />
                        </Grid.Col>

                        <Grid.Col h="90vh" span={6}>
                                    <Image
                                      onClick={() => setSelectedPart("NECKLINE")}
                                    radius="md"
                                    src={selectedItem.parts["NECKLINE"][partsIndexes.NECKLINE].image}
                                    crossOrigin="Anonymous"
                                    style={{
                                        minHeight: '35%',
                                        objectFit: 'contain',
                                        filter: getFilter("NECKLINE"),
                                    }}
                                      onMouseEnter={() => setHovering("NECKLINE")}
                                      onMouseLeave={() => setHovering(null)}
                                  />
                                  <Image
                                      onClick={() => setSelectedPart("BODY")}
                                    radius="md"
                                    src={selectedItem.parts["BODY"][partsIndexes.BODY].image}
                                    crossOrigin="Anonymous"
                                    style={{
                                        minHeight: '45%',
                                        objectFit: 'contain',
                                        filter: getFilter("BODY"),
                                    }}
                                      onMouseEnter={() => setHovering("BODY")}
                                      onMouseLeave={() => setHovering(null)}
                                  />
                                  <Image
                                      onClick={() => setSelectedPart("TOP_TRIM")}
                                    radius="md"
                                    src={selectedItem.parts["TOP_TRIM"][partsIndexes.TOP_TRIM].image}
                                    crossOrigin="Anonymous"
                                    style={{
                                        minHeight: '20%',
                                        objectFit: 'contain',
                                        filter: getFilter("TOP_TRIM"),
                                    }}
                                      onMouseEnter={() => setHovering("TOP_TRIM")}
                                      onMouseLeave={() => setHovering(null)}
                                  />
                        </Grid.Col>

                        <Grid.Col h="90vh" span={6}>
                            <Image
                                onClick={() => setSelectedPart("ARMS")}
                                mt={"30%"}
                                radius="md"
                                src={selectedItem.parts["ARMS"][partsIndexes.ARMS].image}
                                crossOrigin="Anonymous"
                                style={{
                                    height: '40%',
                                    objectFit: 'contain',
                                    filter: getFilter("ARMS"),
                                }}
                                onMouseEnter={() => setHovering("ARMS")}
                                onMouseLeave={() => setHovering(null)}
                            />
                        </Grid.Col>

                        <Grid.Col h="90vh" span={3}>
                            <ActionIcon mt="5vh" w="90%" h="80vh" variant="gradient" aria-label="Next-Part" onClick={() => {handlePartCarousel(selectedPart, 1)}}>
                                <IconChevronRight/>
                            </ActionIcon>
                        </Grid.Col>

                    </Grid>
                }
            </AppShell.Main>
            <AppShell.Aside>
                <ScrollArea h={'80vh'}>
                <Table
                    withTableBorder
                    highlightOnHover
                    withColumnBorders
                    withRowBorders={false}
                >
                    <Table.Thead
                        style={{
                            position: 'sticky',
                            top: 0,
                            backgroundColor: getThemeColor('violet', theme),
                            zIndex: 1
                    }}
                    >
                        <Table.Tr>
                          <Table.Th><Center>Part</Center></Table.Th>
                          <Table.Th><Center>Name</Center></Table.Th>
                          <Table.Th><Center>Price</Center></Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {selectedItem && Object.keys(selectedItem.parts).map((part, partIndex) => {
                            return (
                                <Table.Tr key={partIndex}>
                                    <Table.Td>
                                        <Center>{part.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')}</Center>
                                    </Table.Td>
                                    <Table.Td>
                                        <Center>{selectedItem.parts[part][partsIndexes[part]].name}</Center>
                                    </Table.Td>
                                    <Table.Td>
                                        <Center>
                                            {selectedItem.parts[part][partsIndexes[part]].premium ? "Premium" : selectedItem.parts[part][partsIndexes[part]].price}
                                        </Center>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
                </ScrollArea>
                <Button h="10vh">Confirm Design</Button>
            </AppShell.Aside>
        </React.Fragment>
    );
}

export default PatternBuilderManager;
