// src/context/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import {makeAuthenticatedRequest} from "../Utils/authenticated_request";
import {useLocation} from "react-router-dom";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const location = useLocation();
  const [user, setUser] = useState(null);

  const getCurrentUser = async () => {
    try {
        const response = await makeAuthenticatedRequest(
            `${process.env.REACT_APP_API_HOST}/user/me/`,
        );
        if (response.ok) {
            const jsonData = await response.json();
            setUser(jsonData)
        } else {
            console.error("Request failed with status:", response.status);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
  }


  useEffect(() => {
      if (location.pathname !== "/sign-in" && location.pathname !== "") {
          getCurrentUser()
      }
  }, []);

  const updateUser = (userData) => {
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
