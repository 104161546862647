import {Center, Divider, rem, ScrollArea, SegmentedControl, Stack, Switch, Tooltip} from "@mantine/core";
import {
    IconArrowDownLeft,
    IconArrowDownRight,
    IconArrowsDiagonal,
    IconArrowsMoveHorizontal,
    IconArrowsMoveVertical,
    IconArrowsRight,
    IconArrowsRightLeft,
    IconArrowUpLeft,
    IconArrowUpRight,
    IconRotate,
    IconRotateClockwise,
    IconSpiral
} from "@tabler/icons-react";
import React from "react";

const DirectionCanvas = (
    {
        rowDirection,
        setRowDirection,
        rowClockwise,
        setRowClockwise,
        rowStarting,
        setRowStarting,
        rowSwitching,
        setRowSwitching,
    }) => {
    return (
            <Stack>
                <Divider label="Row Direction" labelPosition="center"/>
                <Center>
                    <SegmentedControl
                        orientation="vertical"
                        value={rowDirection}
                        onChange={setRowDirection}
                        color="violet"
                        data={[
                            {
                                label: (
                                    <Center>
                                        <Tooltip label="Vertical">
                                            <IconArrowsMoveVertical style={{width: rem(16), height: rem(16)}}/>
                                        </Tooltip>
                                        {/*<Box ml={10}>Vertical</Box>*/}
                                    </Center>
                                ),
                                value: 'vertical'
                            },
                            {
                                label: (
                                    <Center>
                                        <Tooltip label="Horizontal">
                                            <IconArrowsMoveHorizontal style={{width: rem(16), height: rem(16)}}/>
                                        </Tooltip>
                                        {/*<Box ml={10}>Horizontal</Box>*/}
                                    </Center>
                                ),
                                value: 'horizontal'
                            },
                            {
                                label: (
                                    <Center>
                                        <Tooltip label="Diagonal">
                                            <IconArrowsDiagonal style={{width: rem(16), height: rem(16)}}/>
                                        </Tooltip>
                                        {/*<Box ml={10}>Diagonal</Box>*/}
                                    </Center>
                                ),
                                value: 'diagonal'
                            },
                            {
                                label: (
                                    <Center>
                                        <Tooltip label="Circular">
                                            <IconSpiral style={{width: rem(16), height: rem(16)}}/>
                                        </Tooltip>
                                        {/*<Box ml={10}>Diagonal</Box>*/}
                                    </Center>
                                ),
                                value: 'spiral'
                            },
                        ]}
                    />
                </Center>
                <Divider label={rowDirection === "spiral" ? "Row Clockwise" : "Row Starting"} labelPosition="center"/>

                <Center>
                    {rowDirection !== "spiral" && <SegmentedControl
                        orientation="vertical"
                        value={rowStarting}
                        onChange={setRowStarting}
                        c="violet"
                        color="violet"
                        data={[
                            {
                                label: (
                                    <Center>
                                        <IconArrowUpLeft style={{width: rem(16), height: rem(16)}}/>
                                        {/*<Box ml={10}>Top Left</Box>*/}
                                    </Center>
                                ),
                                value: 'topLeft'
                            },
                            {
                                label: (
                                    <Center>
                                        <IconArrowUpRight style={{width: rem(16), height: rem(16)}}/>
                                        {/*<Box ml={10}>Top Right</Box>*/}
                                    </Center>
                                ),
                                value: 'topRight'
                            },
                            {
                                label: (
                                    <Center>
                                        <IconArrowDownLeft style={{width: rem(16), height: rem(16)}}/>
                                        {/*<Box ml={10}>Bottom Left</Box>*/}
                                    </Center>
                                ),
                                value: 'bottomLeft'
                            },
                            {
                                label: (
                                    <Center>
                                        <IconArrowDownRight style={{width: rem(16), height: rem(16)}}/>
                                        {/*<Box ml={10}>Bottom Right</Box>*/}
                                    </Center>
                                ),
                                value: 'bottomRight'
                            },
                        ]}
                    />}
                    {rowDirection === "spiral" && <Switch
                        checked={rowClockwise}
                        onChange={(e) => setRowClockwise(e.currentTarget.checked)}
                        size="md"
                        onLabel={<IconRotateClockwise style={{width: rem(16), height: rem(16)}}/>}
                        offLabel={<IconRotate style={{width: rem(16), height: rem(16)}}/>}
                    />}
                </Center>
                <Divider label="Row Switching" labelPosition="center"/>

                <Center>
                    <Switch
                        checked={rowSwitching}
                        onChange={(e) => setRowSwitching(e.currentTarget.checked)}
                        size="md"
                        onLabel={<IconArrowsRightLeft style={{width: rem(16), height: rem(16)}}/>}
                        offLabel={<IconArrowsRight style={{width: rem(16), height: rem(16)}}/>}
                    />
                </Center>
            </Stack>
    )

}

export default DirectionCanvas;